<template>
    <div class="calendar_page__header flex items-center justify-between">
        <h1 v-if="!related_object">Календарь</h1>
        <div class="flex items-center">
            <a-button
                @click="today"
                type="primary" 
                :disabled="todayCheck"
                ghost>
                Сегодня
            </a-button>
            <div class="flex items-center mx-3">
                <a-button type="ui" ghost flaticon icon="fi-rr-angle-small-left" @click="prev" />
                <a-button type="ui" ghost flaticon icon="fi-rr-angle-small-right" @click="next" />
            </div>
            <a-radio-group 
                class="select-none"
                :value="activeType" 
                @change="changeType">
                <a-radio-button value="dayGridMonth">
                    Месяц
                </a-radio-button>
                <a-radio-button value="timeGridWeek">
                    Неделя
                </a-radio-button>
                <a-radio-button value="timeGridDay">
                    День
                </a-radio-button>
                <a-radio-button value="multiMonthYear">
                    Год
                </a-radio-button>
                <a-radio-button :value="mountchActive ? 'listMonth' : 'listWeek'">
                    Список
                </a-radio-button>
            </a-radio-group>
            <transition name="fade">
                <div 
                    v-if="activeType === 'listWeek' || activeType === 'listMonth'" 
                    class="ml-4 flex items-center cursor-pointer"
                    @click="changeSwitchType(mountchActive ? 'listWeek' : 'listMonth')">
                    <a-switch :checked="mountchActive" /> 
                    <span class="ml-2">Месяц</span>
                </div>
            </transition>
        </div>
        <div 
            v-if="addEventCheck" 
            ref="calendarHeaderButton">
            <a-dropdown 
                :getPopupContainer="getPopupContainer"
                :trigger="['hover']">
                <a-button 
                    type="primary" 
                    :size="windowWidth > 1086 ? 'large' : 'default'"
                    icon="fi-rr-plus" 
                    flaticon
                    @click="handleButtonClick">
                    <template v-if="windowWidth > 1086">Добавить событие</template>
                </a-button>
                <a-menu v-if="!related_object" slot="overlay">
                    <a-menu-item key="1" class="flex items-center" @click="addCalendar()">
                        <i class="fi fi-rr-calendar-plus mr-2"></i>
                        Добавить календарь
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        activeType: {
            type: String,
            required: true
        },
        todayCheck: {
            type: Boolean,
            default: true
        },
        today: {
            type: Function,
            default: () => {}
        },
        prev: {
            type: Function,
            default: () => {}
        },
        next: {
            type: Function,
            default: () => {}
        },
        handleChangeType:{
            type: Function,
            default: () => {}
        },
        addCalendar: {
            type: Function,
            default: () => {}
        },
        related_object: {
            type: [String, Number],
            default: null
        },
        relatedInfo: {
            type: Object,
            default: () => null
        },
        uKey: {
            type: [String, Number],
            default: 'default'
        },
        addEventCheck: {
            type: Boolean,
            default: true
        },
        clearEvents: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        }
    },
    data() {
        return {
            mountchActive: false
        }
    },
    created() {
        if(this.activeType === 'listMonth')
            this.mountchActive = true
    },
    methods: {
        changeType(event) {
            const value = event.target.value
            if(value === 'multiMonthYear') {
                this.clearEvents()
            }
            this.handleChangeType(event)
        },
        changeSwitchType(value) {
            if(value === 'listMonth')
                this.mountchActive = true
            else
                this.mountchActive = false
            this.handleChangeType({
                target: {
                    value
                }
            })
        },
        getPopupContainer() {
            return this.$refs.calendarHeaderButton
        },
        handleButtonClick() {
            eventBus.$emit('open_event_form', 
                null, 
                null, 
                null, 
                this.relatedInfo, 
                this.uKey)
        }
    }
}
</script>

<style lang="scss" scoped>
.calendar_page__header{
    padding: 10px 20px;
    h1{
        font-weight: 300;
        font-size: 24px;
        margin: 0px;
    }
}
</style>